import { authContext } from "../adalConfig";

export const isLoggedIn = () => {
    return sessionStorage.getItem('accessKeyId') !== null
    && sessionStorage.getItem('secretAccessKey') !== null
    && sessionStorage.getItem('sessionToken') !== null;
};

export const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    authContext.logOut();
};