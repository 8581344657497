const AWS = require('aws-sdk');
const jwt = require('jsonwebtoken');
/**
 * Request temp credentials from cognito identity pool
 * 1. Gets the Azure token from sessionStorage
 * 2. Use it to build aws-sdk cred get request
 * 3. Save access/secret key + sessionToken to sessionStorage
 * @param dataObj - object that consists of parameters being passed to arbiterSS
 */
export const authenticateCognito = () => {
    let azureResponse = localStorage.getItem("adal.idtoken");
    let idpUrl = process.env.REACT_APP_IDP_URL;
    let identityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
    let region = process.env.REACT_APP_COGNITO_REGION;

    let AWSCredentials = { Logins: {} }
    AWSCredentials.IdentityPoolId = identityPoolId;
    AWSCredentials.Logins[idpUrl + '/v2.0'] = azureResponse;
    AWS.config.region = region

    AWS.config.credentials = new AWS.CognitoIdentityCredentials(AWSCredentials);

    // Obtain AWS credentials
    return new Promise((resolve, reject) => {
        AWS.config.credentials.get(function (err, response) {
            window.history.replaceState({}, 0, window.location.pathname);
            if (err) {
                console.log(err);
                console.log('You are NOT logged into COGNITO.');
                reject();
            } else {
                if (AWS.config.credentials.params.Logins[idpUrl + '/v2.0']) {
                    console.log('You are logged into COGNITO.');
                    let accessKeyId = AWS.config.credentials.accessKeyId;
                    let secretAccessKey = AWS.config.credentials.secretAccessKey;
                    let sessionToken = AWS.config.credentials.sessionToken;
                    sessionStorage.setItem('accessKeyId', accessKeyId);
                    sessionStorage.setItem('secretAccessKey', secretAccessKey);
                    sessionStorage.setItem('sessionToken', sessionToken);
                }
                resolve();
            }
        })
    }).then(() => getUserRole())
    .catch(err => {
        console.log("ERROR: An error occured " + JSON.stringify(err));
    });
}

export const getUserRole = () => {
    let azureResponse = localStorage.getItem("adal.idtoken");
    let decodedToken = jwt.decode(azureResponse);
    let koId = decodedToken.preferred_username && decodedToken.preferred_username.split('@')[0];
    return Promise.resolve(koId);
}
